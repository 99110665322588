import { IGatsbyImageData } from "gatsby-plugin-image"

import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { Video } from "~/models/Video"

export type Image = {
  gatsbyImageData: IGatsbyImageData
}

type ArrayOfNodes<T> = { nodes: T[] }

export interface CarouselPartialVideo {
  hostedVideoUrl: string
  videoPreview: string
  videoPreviewThumbnail: string
}

export type Media = {
  content: CarouselPartialVideo | Image
  caption:
    | string
    | {
        episodeNumber: number
        episodeTitle: string
        episodeDescription: string
        recipeLink?: string
      }
}

export type CocreationStep = {
  id: string
  primary: {
    stepTitle: PrismicStructuredText
    stepDescription: PrismicStructuredText
    stepFinished: boolean
    ctaTitle: PrismicStructuredText
    ctaLink: PrismicLink
  }
  items: [
    {
      image: Image
      caption: string
      videoId: string
    }
  ]
}

export type FormattedCocreationStep = {
  title: PrismicStructuredText
  description: PrismicStructuredText
  finished: boolean
  buttonTitle?: PrismicStructuredText
  buttonLink?: PrismicLink
  media: Media[]
}

export type CocreationPageQuery = {
  prismicCocreationProject: {
    uid: string
    data: {
      started: boolean
      finished: boolean
      title: PrismicStructuredText
      subtitle: PrismicStructuredText
      color: string
      body: CocreationStep[]
    }
  }
  allVideo: ArrayOfNodes<Video>
}

export const isVideo = (arg: any): arg is Video => {
  return arg ? !!arg.videoPreviewThumbnail : false
}

export const isPartialVideo = (arg: any): arg is CarouselPartialVideo => {
  return arg ? !!arg.videoPreviewThumbnail : false
}

export function formatSteps(
  originalSteps: CocreationStep[],
  allVideos: ArrayOfNodes<Video>
) {
  const formattedSteps: FormattedCocreationStep[] = originalSteps.map(step => {
    const media: Media[] = []
    step.items.forEach(item => {
      if (!item.videoId) {
        media.push({ content: item.image, caption: item.caption })
        return
      }
      const videoIsFound = allVideos.nodes.find(
        video => video.id === item.videoId
      )
      videoIsFound &&
        media.push({ content: videoIsFound, caption: item.caption })
    })
    return {
      title: step.primary.stepTitle,
      description: step.primary.stepDescription,
      finished: step.primary.stepFinished,
      buttonTitle: step.primary.ctaTitle,
      buttonLink: step.primary.ctaLink,
      media,
    }
  })

  return formattedSteps
}
