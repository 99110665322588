import React from "react"

import { CarouselPartialVideo, Image, Media } from "../../utils"
import CocreationImageCard from "../CocreationImageCard/CocreationImageCard"
import { Video } from "~/models/Video"

import css from "./ThumbnailsModal.module.scss"

type Props = {
  media: Media[]
  thumbnail: CarouselPartialVideo | Image | undefined
  openCarouselModal: () => void
  setMediaIndex: (arg: number) => void
}
const ThumbnailsModal = (props: Props) => {
  const { media, thumbnail, openCarouselModal, setMediaIndex } = props
  const isUniqueMedia = media.length === 1
  function openModal() {
    setMediaIndex(1)
    openCarouselModal()
  }
  return (
    <div className={css.root}>
      {thumbnail && isUniqueMedia ? (
        <CocreationImageCard
          thumbnail={thumbnail}
          caption={media[0].caption as string}
          mediaLength={media.length}
          onClick={() => openCarouselModal()}
        />
      ) : (
        thumbnail &&
        media[1].content && (
          <div className={css.multipleImages}>
            {thumbnail && (
              <CocreationImageCard
                thumbnail={thumbnail}
                caption={media[0].caption as string}
                mediaLength={media.length}
                onClick={() => openCarouselModal()}
              />
            )}
            {media[1].content && (
              <CocreationImageCard
                thumbnail={media[1].content}
                caption={media[1].caption as string}
                mediaLength={media.length}
                allowedIcon={media.length === 2}
                onClick={() => openModal()}
              />
            )}
          </div>
        )
      )}
    </div>
  )
}

export default ThumbnailsModal
