import React, { CSSProperties } from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import CocreationArticle from "./CocreationArticle/CocreationArticle"
import CocreationProgressiveBar from "./CocreationProgressiveBar/CocreationProgressiveBar"
import {
  CocreationPageQuery,
  formatSteps,
  FormattedCocreationStep,
} from "./utils"
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb"
import KlaviyoForm from "~/components/slices/ExternalIntegrations/KlaviyoForm/KlaviyoForm"
import { Col, Container, Heading, Row } from "~/components/ui"

import css from "./Cocreation.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: CocreationPageQuery
  pageContext: TemplateDefaultPropTypes["pageContext"] & PageContextCocreation
}
type PageContextCocreation = {
  klaviyoFormClassName: string
}

function Cocreation({ pageContext, data, ...props }: PropTypes) {
  const intl = useIntl()
  const locale = intl.locale
  const cocreationParent = [
    {
      label: intl.formatMessage({
        id: "header/section:community",
      }),
      link: `/${locale}/${intl.formatMessage({
        id: "path:community",
      })}`,
    },
    {
      label: intl.formatMessage({
        id: "cocreation/breadcrumb",
      }),
      link: `/${locale}/${intl.formatMessage({
        id: "path:community",
      })}/${intl.formatMessage({
        id: "path:cocreation",
      })}`,
    },
  ]
  const content = data.prismicCocreationProject.data
  const calculatePercentage = (steps: FormattedCocreationStep[]) => {
    const finishedSteps = steps.filter(step => step.finished)
    return (
      ((content.finished ? finishedSteps.length + 1 : finishedSteps.length) /
        (steps.length + 1)) *
      100
    )
  }

  const formattedSteps = formatSteps(content.body, data.allVideo)
  const progress = Math.round(calculatePercentage(formattedSteps))
  return (
    <div
      className={css.root}
      style={{
        ...({
          "--project-main-color": content.color,
        } as CSSProperties),
      }}
    >
      <Breadcrumb
        pageTitle={content.title.text}
        multipleParents={cocreationParent}
      />
      <Container>
        <Row>
          <Col width={[12, 8]}>
            <Heading variant="extraLarge" bold>
              {content.title.text}
            </Heading>
            <Heading variant="extraSmall" semiBold noMargin>
              {content.subtitle.text}
            </Heading>
            <CocreationProgressiveBar percentage={progress} />
            <div className={css.articles}>
              {formattedSteps.map((step, index) => (
                <CocreationArticle
                  key={index}
                  lastArticle={
                    index === formattedSteps.length - 1 ? true : false
                  }
                  step={step}
                  previousStepIsFinished={
                    index
                      ? content.body[index - 1].primary.stepFinished
                      : content.started
                  }
                  projectIsFinished={content.finished}
                />
              ))}
            </div>
            <div className={css.footer}>
              <div className={css.achievementBar} />
              <Heading
                variant="medium"
                bold
                noMargin
                className={css.startingTitle}
              >
                {intl.formatMessage({ id: "cocreation/text:start-project" })}
              </Heading>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={css.klaviyoForm}>
        <KlaviyoForm
          className={pageContext.klaviyoFormClassName}
          fullWidth={true}
        />
      </div>
    </div>
  )
}

export default withTemplate(Cocreation)

export const query = graphql`
  query CocreationPage($uid: String, $allVideoIds: [String]) {
    prismicCocreationProject(uid: { eq: $uid }) {
      id
      uid
      data {
        started
        finished
        title {
          ...PrismicStructuredText
        }
        subtitle {
          ...PrismicStructuredText
        }
        color
        body {
          ... on PrismicCocreationProjectDataBodyCocreationProjectStep {
            id
            primary {
              stepTitle: step_title {
                ...PrismicStructuredText
              }
              stepDescription: step_description {
                ...PrismicStructuredText
              }
              ctaTitle: cta_title {
                ...PrismicStructuredText
              }
              ctaLink: cta_link {
                ...PrismicLink
              }
              stepFinished: step_finished
            }
            items {
              image {
                gatsbyImageData(width: 500, placeholder: BLURRED)
              }
              caption
              videoId: video_id
            }
          }
        }
      }
    }
    allVideo(filter: { id: { in: $allVideoIds } }) {
      nodes {
        ...Video
      }
    }
  }
`
