import React, { useEffect } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import FullscreenCarousel from "../FullscreenCarousel/FullscreenCarousel"
import Modal from "~/components/Modals/Modal"
import { Episode } from "~/models/Show"
import { Media } from "~/templates/Cocreation/utils"

import css from "./FullscreenCarouselModal.module.scss"

interface PropTypes {
  isOpen: boolean
  closeModal: () => void
  posts: Media[] | Episode[]
  mediaIndex?: number
  awaitData?: boolean
  totalPostCount?: number
  onCanLoadMore?: () => void
  openInFullscreen?: boolean
  autoplay?: boolean
  accentColor?: string
  swipeHint?: boolean
}

function FullscreenCarouselModal({
  isOpen,
  closeModal,
  mediaIndex = 0,
  posts,
  awaitData,
  totalPostCount,
  onCanLoadMore,
  openInFullscreen,
  autoplay,
  accentColor = "var(--original-color)",
  swipeHint,
}: PropTypes) {
  const intl = useIntl()

  //TODO : Use the function introduced with Classics PR
  const close = () => {
    const body = document.querySelector("body")
    const html = document.querySelector("html")
    if (window.location.hash) {
      //Remove hash from URL
      history.pushState("", document.title, window.location.pathname)
    }
    if (body) {
      const baseScrollBehaviorBody = body.style.scrollBehavior
      body.style.scrollBehavior = "auto"
      setTimeout(() => {
        body.style.scrollBehavior = baseScrollBehaviorBody
      }, 200)
    }
    if (html) {
      const baseScrollBehaviorHtml = html.style.scrollBehavior
      html.style.scrollBehavior = "auto"
      setTimeout(() => {
        html.style.scrollBehavior = baseScrollBehaviorHtml
      }, 200)
    }

    closeModal()
  }

  useEffect(() => {
    setTimeout(() => {
      const ibudendaPadlock =
        document.querySelector<HTMLElement>(".iubenda-tp-btn")
      if (ibudendaPadlock) {
        ibudendaPadlock.style.setProperty("display", "none", "important")
      }
    }, 1000)
  }, [])

  return (
    <Modal
      open={isOpen}
      onClose={close}
      mode="fixed"
      backgroundOpacity={1}
      zIndex={100000}
      overflow="hidden"
    >
      {(modalRef, isOpen) => (
        <div
          className={cn(css.modal, { [css.openedModal]: isOpen })}
          ref={modalRef}
        >
          <FullscreenCarousel
            {...{ onCanLoadMore, awaitData, totalPostCount }}
            posts={posts}
            mediaIndex={mediaIndex}
            openInFullscreen={openInFullscreen}
            autoplay={autoplay}
            accentColor={accentColor}
            close={close}
            swipeHint
          />
          <button
            className={css.close}
            onClick={close}
            title={intl.formatMessage({ id: "action:close" })}
          />
        </div>
      )}
    </Modal>
  )
}

export default FullscreenCarouselModal
