import React, { CSSProperties, useState } from "react"

import cn from "classnames"

import CocreationCarouselModal from "../../../components/FullscreenCarouselModal"
import { FormattedCocreationStep, isPartialVideo, isVideo } from "../utils"
import CocreationImageCard from "./CocreationImageCard/CocreationImageCard"
import minusIcon from "./minusIcon.svg"
import plusIcon from "./plusIcon.svg"
import ThumbnailsModal from "./ThumbnailsModal/ThumbnailsModal"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Body, Heading, HrefButton } from "~/components/ui"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./CocreationArticle.module.scss"

type Props = {
  step: FormattedCocreationStep
  previousStepIsFinished: boolean
  lastArticle: boolean
  projectIsFinished?: boolean
}
const CocreationArticle = (props: Props) => {
  const {
    step,
    previousStepIsFinished,
    lastArticle,
    projectIsFinished = false,
  } = props
  const [isNested, setIsNested] = useState(!lastArticle)
  const [mediaIndex, setMediaIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const onClick = () => {
    setIsNested(!isNested)
  }
  const thumbnailExists =
    !!step.media.length &&
    (isPartialVideo(step.media[0].content)
      ? step.media[0].content.hostedVideoUrl
      : step.media[0].content.gatsbyImageData)

  const buttonExists =
    !!step.buttonLink &&
    !!getPrismicLinkProps(step.buttonLink)?.href &&
    !!step.buttonTitle?.text

  return (
    <div
      className={cn(css.root, {
        [css.nested]: isNested,
        [css.displayed]: !isNested,
      })}
      style={{
        ...({
          "--current-step-color":
            step.finished || projectIsFinished
              ? "var(--project-main-color)"
              : "var(--gray4-color)",
          "--next-step-color":
            (step.finished && !lastArticle) || projectIsFinished
              ? "var(--project-main-color)"
              : "var(--gray4-color)",
          "--previous-step-color":
            previousStepIsFinished || projectIsFinished
              ? "var(--project-main-color)"
              : "var(--gray4-color)",
        } as CSSProperties),
      }}
    >
      {isOpen && (
        <CocreationCarouselModal
          posts={step.media}
          isOpen={isOpen}
          closeModal={() => {
            setMediaIndex(0)
            setIsOpen(false)
          }}
          mediaIndex={mediaIndex}
        />
      )}
      <div className={css.achievementBar} />

      <div className={css.container}>
        <div className={css.nestedContainer}>
          {thumbnailExists && (
            <div className={css.nestedThumbnail}>
              <CocreationImageCard
                thumbnail={step.media[0].content}
                caption={step.media[0].caption as string}
                mediaLength={step.media.length}
                onClick={() => {
                  setIsOpen(true)
                }}
              />
            </div>
          )}
          <div className={css.heading} onClick={onClick}>
            <div className={css.text}>
              <Heading variant="small" semiBold noMargin className={css.title}>
                {step.title.text}
              </Heading>
              {step.description.text && (
                <Body
                  variant={isNested ? "body4" : "body3"}
                  className={css.description}
                  noMargin
                >
                  {step.description.text}
                </Body>
              )}
            </div>
            {/* TODO : Change Icon */}
            <ColorableSVG
              href={isNested ? plusIcon : minusIcon}
              height={20}
              width={20}
              style={{ ...{ flexShrink: 0 } }}
            />
          </div>
        </div>
        <div className={css.displayedThumbnail}>
          {thumbnailExists && (
            <ThumbnailsModal
              media={step.media}
              thumbnail={step.media[0].content}
              openCarouselModal={() => setIsOpen(true)}
              setMediaIndex={setMediaIndex}
            />
          )}
        </div>
        {buttonExists && (
          <HrefButton
            color={"original"}
            variant="primary"
            href={step.buttonLink && getPrismicLinkProps(step.buttonLink)?.href}
            size="small"
            fullWidth
            className={css.button}
          >
            {step.buttonTitle?.text}
          </HrefButton>
        )}
      </div>
    </div>
  )
}

export default CocreationArticle
