import React, { CSSProperties, useEffect, useRef, useState } from "react"

import cn from "classnames"
import debounce from "lodash/debounce"
import { useIntl } from "react-intl"

import arrow from "../assets/arrow.svg"
import FullscreenCarouselSlide from "../FullscreenCocreationSlide/FullscreenCarouselSlide"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Body } from "~/components/ui"
import useEventListener from "~/hooks/useEventListener"
import useIsomorphicLayoutEffect from "~/hooks/useIsomorphicLayoutEffect"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import useVisualViewport from "~/hooks/useVisualViewport"
import { Episode, isEpisode } from "~/models/Show"
import { Media } from "~/templates/Cocreation/utils"

import css from "./FullscreenCarousel.module.scss"

interface PropTypes {
  mediaIndex: number
  posts: Media[] | Episode[]
  totalPostCount?: number
  openInFullscreen?: boolean
  autoplay?: boolean
  accentColor?: string
  close?: (open: false) => void
  swipeHint?: boolean
}

let interval: any = null
const SENSITIVITY_THRESHOLD = 50 //Reduce will increase the sensitivity
const SPEED_THRESHOLD = 30 //Reduce will increase the the speed

function FullscreenCarousel({
  mediaIndex,
  totalPostCount,
  autoplay,
  openInFullscreen,
  accentColor = "var(--original-color)",
  close,
  swipeHint,
  ...props
}: PropTypes) {
  const intl = useIntl()
  const isCompact = useIsOnCompact()

  const posts = props.posts
  const carouselRef = useRef<HTMLUListElement | null>(null)
  const refs = useRef(posts.map(() => React.createRef<HTMLLIElement>()))
  const size = useVisualViewport()
  const [activeIndex, setActiveIndex] = useState(mediaIndex)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(openInFullscreen)
  const [displaySwipeHint, setDisplaySwipeHint] = useState(swipeHint)

  const [positionX, setPositionX] = useState<number | null>(null)
  const [baseScrollClient, setBaseScrollClient] = useState<number | null>(null)
  const [touchMove, setTouchMove] = useState<number | null>(null)
  const [isMuted, setIsMuted] = useState(false)

  const scroolTo = (goTo: number, direction: -1 | 1) => {
    const delta = Math.abs(goTo / SPEED_THRESHOLD)
    interval && clearInterval(interval)
    interval = setInterval(() => {
      if (carouselRef.current && goTo) {
        const distanceToLeft =
          carouselRef.current?.children[
            activeIndex + direction
          ].getBoundingClientRect().left
        if (distanceToLeft * direction !== 0) {
          carouselRef.current.scrollLeft += direction * delta
          goTo += direction * delta
          if (distanceToLeft * direction <= 0) {
            carouselRef.current.scrollLeft +=
              carouselRef.current?.children[
                activeIndex + direction
              ].getBoundingClientRect().left
            setActiveIndex(prev => prev + direction)
            setTimeout(() => {
              if (carouselRef.current) {
                carouselRef.current.style.scrollSnapType = "x mandatory"
                carouselRef.current.style.overflowX = "auto"
              }
            }, 100)
            clearInterval(interval)
          }
        }
      }
    }, 1)
  }

  const prev = () => {
    if (activeIndex > 0) setActiveIndex(prev => prev - 1)
  }

  const prevSmooth = () => {
    //get left distance of previous slide
    const prevSlide =
      carouselRef.current?.children[activeIndex - 1].getBoundingClientRect()
    const goTo = prevSlide?.left && Math.abs(prevSlide?.left)
    goTo && scroolTo(goTo, -1)
  }

  const nextSmooth = () => {
    //get left distance of next slide
    const prevSlide =
      carouselRef.current?.children[activeIndex + 1].getBoundingClientRect()
    const goTo = prevSlide?.left && Math.abs(prevSlide?.left)
    goTo && scroolTo(goTo, 1)
  }

  const next = () => {
    if (activeIndex < posts.length - 1) setActiveIndex(prev => prev + 1)
  }

  const handleTouchMove = (e: any) => {
    e.preventDefault()
    clearInterval(interval)
    displaySwipeHint && setDisplaySwipeHint(false)
    if (positionX === null) {
      setPositionX(e.touches[0].clientX)
      if (carouselRef.current) {
        setBaseScrollClient(carouselRef.current.scrollLeft)
      }
    }
    if (carouselRef.current && baseScrollClient && positionX) {
      carouselRef.current.style.scrollSnapType = "none"
      setTouchMove(e.touches[0].clientX - positionX)
      carouselRef.current.scrollLeft =
        baseScrollClient + positionX - e.touches[0].clientX
    }
  }

  const handleTouchStart = (e: any) => {
    clearInterval(interval)
    displaySwipeHint && setDisplaySwipeHint(false)
    if (positionX === null) {
      setPositionX(e.touches[0].clientX)
    }
  }
  const handleTouchCancel = (e: any) => {
    if (positionX !== null) {
      setTouchMove(e.changedTouches[0].clientX - positionX)
      if (carouselRef.current) {
        carouselRef.current.style.overflowX = "hidden"
        if (
          touchMove !== null &&
          touchMove > SENSITIVITY_THRESHOLD &&
          activeIndex > 0
        ) {
          //prevSmooth()
        } else if (
          touchMove !== null &&
          touchMove < -SENSITIVITY_THRESHOLD &&
          activeIndex < posts.length - 1
        ) {
          //nextSmooth()
        } else {
          setTimeout(() => {
            if (carouselRef.current) {
              carouselRef.current.style.scrollSnapType = "x mandatory"
              carouselRef.current.style.overflowX = "auto"
            }
          }, 100)
        }
      }
      setBaseScrollClient(null)
      setPositionX(null)
    }
  }

  const scrollIntoView = (element: HTMLLIElement | null) => {
    if (element) {
      element.scrollIntoView({
        block: "center",
        behavior: isInitialRender ? "auto" : "smooth",
        inline: "center",
      })
    }
  }

  useIsomorphicLayoutEffect(() => {
    scrollIntoView(refs.current[activeIndex]?.current)
    const actualPost = posts[activeIndex]
    if (isEpisode(actualPost)) {
      if (window.location.hash) {
        history.replaceState(
          {},
          "",
          window.location.origin +
            window.location.pathname +
            `#${actualPost.slug}`
        )
      } else {
        window.location.hash = `#${actualPost.slug}`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const handleHashChange = () => {
    if (!window.location.hash) {
      close && close(false)
    }
  }

  // Update activeIndex on scroll/touch event
  const handleScroll = debounce(() => {
    if (refs.current) {
      refs.current.forEach((ref, index) => {
        // Wait scroll animation
        const clientRect = ref.current?.getBoundingClientRect()

        if (Math.abs(clientRect?.x ?? 0) < window.innerWidth / 2) {
          setActiveIndex(index)
        }
      })
    }
  }, 50)
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false)
    }
    setTimeout(() => {
      setDisplaySwipeHint(false)
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //useEventListener("touchend", handleTouchCancel, carouselRef)
  //useEventListener("touchstart", handleTouchStart, carouselRef)
  useEventListener("scroll", handleScroll, carouselRef)

  useEventListener("hashchange", handleHashChange)

  useEventListener("keydown", (event: KeyboardEvent) => {
    if (["ArrowRight", "ArrowLeft"].includes(event.key)) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }

    switch (event.key) {
      case "ArrowRight":
        next()
        break
      case "ArrowLeft":
        prev()
        break

      default:
        break
    }
  })

  return (
    <div
      style={
        { "--app-height": `${size.visualViewport.height}px` } as CSSProperties
      }
      className={css.carouselWrapper}
    >
      <ul ref={carouselRef} className={css.carousel}>
        {posts.map((item, index) => (
          <FullscreenCarouselSlide
            key={index}
            setIsMuted={setIsMuted}
            isMuted={isMuted}
            {...{
              item,
              index,
              posts,
              refs,
              autoplay,
              isFullscreen,
              setIsFullscreen,
              accentColor,
            }}
          />
        ))}
      </ul>
      {displaySwipeHint && (
        <div
          className={css.swipeHint}
          style={
            accentColor
              ? ({
                  "--swipe-hint-color": accentColor,
                } as CSSProperties)
              : {}
          }
        >
          <Body variant="body2" semiBold color="white">
            {intl.formatMessage({ id: "show/text:swipe-hint" })}
          </Body>
        </div>
      )}

      {/* TODO : Change Icon */}
      {activeIndex !== 0 && (
        <ColorableSVG
          onClick={prev}
          className={cn(css.arrow, css.prev)}
          title={intl.formatMessage({ id: "action/prev" })}
          href={arrow}
          color="var(--white-color)"
          height={24}
          width={24}
        />
      )}
      {activeIndex !== posts.length - 1 && (
        <ColorableSVG
          onClick={next}
          className={cn(css.arrow, css.next)}
          title={intl.formatMessage({ id: "action/next" })}
          href={arrow}
          color="var(--white-color)"
          height={24}
          width={24}
        />
      )}

      <div className={css.counter}>
        <Body
          variant={isCompact ? "body1" : "body3"}
          color="white"
          align="center"
          semiBold={!isCompact}
          bold={isCompact}
        >
          {activeIndex + 1} / {posts.length}
        </Body>
      </div>
    </div>
  )
}

export default FullscreenCarousel
