import React, { CSSProperties, useEffect, useState } from "react"

import { useIntl } from "react-intl"

import { Heading } from "~/components/ui"

import css from "./CocreationProgressiveBar.module.scss"

type Props = {
  percentage: number
}
const CocreationProgressiveBar = (props: Props) => {
  const intl = useIntl()

  const [progressivePercentage, setProgressivePercentage] = useState(0)
  const { percentage } = props
  useEffect(() => {
    if (progressivePercentage < percentage) {
      const progressBar = setTimeout(
        () => setProgressivePercentage(progressivePercentage + 1),
        (progressivePercentage / percentage) * 40
      )
      return () => clearTimeout(progressBar)
    }
  }, [progressivePercentage, percentage])
  return (
    <div
      className={css.root}
      style={{
        ...({
          "--achieved-color":
            percentage === 100
              ? "var(--project-main-color)"
              : "var(--gray4-color)",
          ...({ "--percentage": `${progressivePercentage}%` } as CSSProperties),
        } as CSSProperties),
      }}
    >
      <div className={css.achievementBar} />
      <div className={css.content}>
        <div className={css.heading}>
          <Heading variant="medium" bold noMargin>
            {intl.formatMessage({
              id:
                percentage === 100
                  ? "cocreation/text:finished-project"
                  : "cocreation/text:pending-project",
            })}
          </Heading>
        </div>
        <div
          className={css.progressBar}
          style={
            {
              "--progressBar-final-color":
                progressivePercentage === 100
                  ? "var(--project-main-color)"
                  : "var(--gray4-color)",
            } as CSSProperties
          }
        />
      </div>
    </div>
  )
}

export default CocreationProgressiveBar
