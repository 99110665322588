import { graphql } from "gatsby"

export interface Show {
  id: string
  title: string
  tags: string[]
  baseline: string
  synopsis: string
  trailerPoster: string
  hostedVideoUrl: string
  showPoster: string
  showPosterThumbnail: string
  episodeCount: number
  showLogo: string
  slug: string
  mainColor: string
  position: number
  seoTitle: string
  isTeaser: boolean
}

export interface PartialShow {
  id: string
  title: string
  slug: string
}

export interface Episode {
  seasonNumber: number
  title: string
  videoPoster: string
  videoPosterThumbnail: string
  id: string
  episodeNumber: number
  synopsis: string
  videoDuration: number
  hostedVideoUrl: string
  show: string
  slug: string
}

export interface PartialEpisode {
  id: string
  title: string
  videoPosterThumbnail: string
  hostedVideoUrl: string
  show: string
  showTitle?: string
  showSlug?: string
  slug: string
}

export const query = graphql`
  fragment Episode on Episode {
    seasonNumber: season_number
    title
    videoPoster: video_poster
    videoPosterThumbnail: video_poster_thumbnail
    id
    episodeNumber: episode_number
    synopsis
    videoDuration: video_duration
    hostedVideoUrl: hosted_video_url
    show
    slug
  }
  fragment Show on Show {
    id
    title
    tags
    baseline
    synopsis
    trailerPoster: trailer_poster
    hostedVideoUrl: hosted_video_url
    showPoster: show_poster
    showPosterThumbnail: show_poster_thumbnail
    episodeCount: episode_count
    showLogo: show_logo
    slug
    mainColor: main_color
    position
    seoTitle: seo_title
    isTeaser: is_teaser
  }
`

export const isEpisode = (arg: any): arg is Episode => {
  return arg ? !!arg.seasonNumber : false
}
