import React from "react"

import { useIntl } from "react-intl"

import { Body, Heading } from "~/components/ui"
import { Episode, isEpisode } from "~/models/Show"
import { Media } from "~/templates/Cocreation/utils"

import css from "./Caption.module.scss"

interface PropTypes {
  item: Media | Episode
}
const Caption = ({ item }: PropTypes) => {
  const intl = useIntl()
  return isEpisode(item) ? (
    <div className={css.informations}>
      <Body variant="body4" color="gray3">
        {`${intl.formatMessage({ id: "show/text:episode" })} ${
          item.episodeNumber
        }`}
      </Body>
      <Heading variant="extraSmall" color="white">
        {item.title}
      </Heading>
      <Body variant="body4" color="gray6">
        {item.synopsis}
      </Body>
    </div>
  ) : (
    <Body variant="body2" color="white" className={css.message} noMargin>
      {item.caption}
    </Body>
  )
}

export default Caption
